<template>
    <div>
        <app-layout>
            <Header :title="$t('discipline_follow').toUpper()" @filter-div-status="datatable.filterStatus = $event"
                :is-columns="true">
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column, i) in datatable.columns" :key="i">
                        <b-form-checkbox :id="'checkbox-' + i" :name="'checkbox-' + i" :value="false"
                            :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </template>
            </Header>
            <HeaderMobile :title="$t('discipline_follow').toUpper()"
                @filter-div-status="datatable.filterStatus = $event" :is-columns="true">
                <template v-slot:columns>
                    <div class="mb-1" v-for="(column, i) in datatable.columns" :key="i">
                        <b-form-checkbox :id="'checkbox-' + i" :name="'checkbox-' + i" :value="false"
                            :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                            {{ column.label }}
                        </b-form-checkbox>
                    </div>
                </template>
            </HeaderMobile>
            <div>
                <datatable-filter @filter="filter" @filterClear="filterClear"
                    :export-excel="checkPermission('discipline_excelexport')" @exportExcel="exportExcel">
                    <b-row>
                        <b-col sm="4">
                            <b-form-group :label="this.$t('student_number')">
                                <b-form-input v-model="datatable.queryParams.filter.student_number" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="this.$t('name')">
                                <b-form-input v-model="datatable.queryParams.filter.name" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="this.$t('surname')">
                                <b-form-input v-model="datatable.queryParams.filter.surname" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="this.$t('decision_number')">
                                <b-form-input v-model="datatable.queryParams.filter.decision_number" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="this.$t('decision_date')">
                                <select-date class="filter_date" v-model="datatable.queryParams.filter.decision_date" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="$t('department')">
                                <department-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.department_code"></department-selectbox>
                            </b-form-group>

                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="$t('program')">
                                <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                    :department_code="datatable.queryParams.filter.department_code"
                                    v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="$t('status')">
                                <status-selectbox v-model="datatable.queryParams.filter.decision" :placeholder="$t('select')"
                                    :setOptions="[
                                        { text: $t('approved'), value: 'approved' },
                                        { text: $t('waiting'), value: 'waiting' },
                                        { text: $t('declined'), value: 'declined' },
                                ]"></status-selectbox>
                            </b-form-group>
                        </b-col>




                    </b-row>
                </datatable-filter>
                <datatable @on-page-change="onPageChange" @on-per-page-change="onPerPageChange" :rows="datatable.rows"
                    :columns="datatable.columns" :query-params="datatable.queryParams" v-show="datatable.showTable"
                    :is-loading="datatable.isLoading"></datatable>
                <CommonModal ref="formModal" size="xl" :onHideOnlyX="true"
                    @bHideModalHeaderClose="() => { this.show = false }">
                    <template v-slot:CommonModalTitle>
                        {{ $t('show').toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <show-form v-if="show" :form-id="formId"></show-form>
                    </template>
                </CommonModal>
            </div>
        </app-layout>
    </div>
</template>
<script>
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import ShowForm from "./DisciplineInformationForm"
import DisciplinesService from "@/services/DisciplinesService";
import qs from "qs"
import moment from "moment/moment";
import AppLayout from "@/layouts/AppLayout";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import Header from "@/layouts/AppLayout/Header";
import DatatableFilter from "@/components/datatable/DatatableFilter.vue";
import SelectDate from "@/components/interactive-fields/SelectDate.vue";

export default {
    components: {
        DatatableFilter,
        Header,
        HeaderMobile,
        AppLayout,
        ShowForm,
        CommonModal,
        Datatable,
        SelectDate,
    },
    data() {
        return {
            show: false,
            datatable: {
                rows: [],
                showTable: true,
                isLoading: false,
                filterStatus: true,
                total: 0,
                queryParams: {
                    filter: {
                        student_number: null,
                        name: null,
                        surname: null,
                        decision_number: null,
                        decision: null,
                        decision_date: null,
                        stay_of_execution_decision: null,
                        punishment: null
                    },
                    limit: 20,
                    page: 1
                },
                columns: [
                    {
                        label: "",
                        html: true,
                        field: "buttons",
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'discipline_show',
                                callback: (row) => {
                                    this.showForm(row.id)
                                },
                            }
                        ]
                    },
                    {
                        field: "student_number",
                        label: this.toUpperCase("student_number"),
                        hidden: false,
                        sortable: false
                    },
                    { field: "name", label: this.toUpperCase("name"), hidden: false, sortable: false },
                    { field: "surname", label: this.toUpperCase("surname"), hidden: false, sortable: false },
                    {
                        field: "decision_number",
                        label: this.toUpperCase("decision_number"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: ("decision_text"),
                        label: this.toUpperCase("decision"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: ("punishment_text"),
                        label: this.toUpperCase("action"),
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: "decision_date", label: this.toUpperCase("decision_date"), formatFn: (value) => {
                            return value ? this.toLocaleDate(value) : ''
                        },
                        hidden: false,
                        sortable: false
                    },
                    {
                        field: "stay_of_execution_decision",
                        label: this.toUpperCase("status"),
                        formatFn: (value) => {
                            return value ? this.$t('Continue') : this.$t('Cancel')
                        },
                        hidden: false,
                        sortable: false,

                    },
                ]
            },
            options: [
                { value: true, text: this.$t('yes') },
                { value: false, text: this.$t('no') },
            ],
            formId: null,
            formProcess: null,
        }
    },
    async created() {
        await this.getRows()
    },
    methods: {
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows()
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: { ...this.datatable.queryParams },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            DisciplinesService.getAll(config).then((res) => {
                let data = res.data.data;
                let newData = data.map(discipline => {
                    return {
                        ...discipline,
                        stay_of_execution_decision: discipline.stay_of_execution_decision ? "Evet" : "Hayır",
                        decision_date: moment(discipline.decision_date).format("DD.MM.YYYY"),
                        punishment_start_date: moment(data.punishment_start_date).format("DD.MM.YYYY"),
                        punishment_end_date: moment(data.punishment_end_date).format("DD.MM.YYYY")
                    }
                })
                this.datatable.rows = newData;
                this.datatable.total = res.data.pagination.total;
            }).catch(e => this.showErrors(e)).finally(() => this.datatable.isLoading = false)
        },
        showForm(id) {
            this.formId = id;
            this.show = true;
            this.showModal();
        },
        showModal() {
            this.$refs.formModal.$refs.commonModal.show();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        exportExcel() {
            const config = {
                params: { ...this.datatable.queryParams },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            DisciplinesService.exportExcel(config).then(res => this._downloadFile(res, this.$t('discipline_follow'), '.xlsx')).catch(err => this.showErrors(err))
        }
    }

}
</script>

<style>
.filter_date input {
    background-color: white !important;
}
</style>
